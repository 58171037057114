export const ApiBaseUrl = process.env.VUE_APP_API_URL;

export const QRQueryParams = {
  Id: 'id',
  Action: 'action',
  MediaType: 'type',
  MediaSerial: 'serial',
  DeviceId: 'deviceId',
};

export const MobileAppRegistrationParams = {
  Hash: 'ma',
};

export const NexiRedirectParams = {
  Checkout: 'checkout',
  CheckoutMsg: 'messaggio',
};

export const VendonTopUpParams = {
  WebOrderId: 'webOrderId',
  MerchantId: 'merchantId',
  AuthorisationUuid: 'authorisation_uuid',
};

export const QRActions = {
  QrCheck: 'QrCheck',
  Recharge: 'recharge',
  Unknown: 'Unknown',
};

export const TrackEvents = {
  SessionError: 'SessionError',
  SessionRestoredSuccessfully: 'SessionRestoredSuccessfully',
  SessionRestoredError: 'SessionRestoredError',
};

export const HttpErrors = {
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
};

export const SupportedLocales = ['cs', 'de', 'en', 'es', 'fr', 'it'];
