import { Routes } from '@/router/routes';

export default [
  {
    path: '/',
    name: Routes.Home,
    component: (): unknown => import(`@/views/Home.vue`),
  },
  {
    path: '/privacy',
    name: Routes.Privacy,
    component: (): unknown => import(`@/views/privacy/Privacy.vue`),
  },
  {
    path: '/cookies',
    name: Routes.Cookies,
    component: (): unknown => import(`@/views/cookies/Cookies.vue`),
  },
  {
    path: '/post/:id',
    name: Routes.BlogPost,
    component: (): unknown => import(`@/views/blog/BlogPost.vue`),
  },
  {
    path: '/qr-check/:id',
    name: Routes.QRCheck,
    component: (): unknown =>
      import(`@/views/qrcode-registration/QRLayout.vue`),
  },
  {
    path: '/mobileapp-registration/:ma',
    name: Routes.MobileAppRegistration,
    component: (): unknown =>
      import(`@/views/mobileapp-registration/MobileAppRegistration.vue`),
  },
  {
    path: '/pre-customer/:id?',
    name: Routes.PreCustomer,
    component: (): unknown =>
      import(`@/views/customer/PreCustomerDashboard.vue`),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/customer/:id?',
    name: Routes.Customer,
    component: (): unknown => import(`@/views/customer/Customer.vue`),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/vendon/payment/result',
    name: Routes.VendonTopUp,
    component: (): unknown => import(`@/views/vendon/VendonTopUp.vue`),
    meta: {
      requiresAuth: false,
    },
  },
];
