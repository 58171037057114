import { WebPaymentOrderType } from './webPaymentOrderType';

export class WebPaymentOrder {
  public id!: string;
  public merchantId!: string;
  public mediaId!: string;
  public deviceId!: string;
  public webPaymentServiceId!: string;
  public amount!: number;
  public serviceOtp!: number;
  public locale!: string;
  public vendonCardUuid!: string;
  public webOrderType!: WebPaymentOrderType;
}
