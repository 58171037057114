











































































import i18n from '@/i18n';
import { Component, Vue } from 'vue-property-decorator';
import { RootActions } from '@/store/enums';
import { Routes } from '@/router/routes';
import { SupportedLocales } from '@/models/common/constants';
import { urlQueryService } from '@/services/common/urlquery.service';
import { vendonGatewayService } from '@/services/vendon/vendongateway.service';

@Component
export default class VendonTopUp extends Vue {
  public receivedOutcome = false;
  public receivedError = false;
  protected authorisationUuid!: string | undefined;
  protected merchantId!: string | undefined;
  protected webOrderId!: string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected internalApiResponse: any = '';

  get isSuccess(): boolean {
    return (
      this.receivedOutcome && this.internalApiResponse?.state === 'success'
    );
  }

  get isFailure(): boolean {
    return (
      this.receivedOutcome &&
      (this.internalApiResponse?.state === 'canceled' ||
        this.internalApiResponse?.state === 'failed' ||
        this.internalApiResponse?.state === 'timeout')
    );
  }

  get isPending(): boolean {
    return (
      !this.receivedOutcome ||
      this.internalApiResponse?.state === 'approval' ||
      this.internalApiResponse?.state === 'processing'
    );
  }

  get merchantName(): string {
    if (!this.receivedOutcome) return '';
    return this.internalApiResponse.merchant_name;
  }

  get legalAddress(): string {
    if (!this.receivedOutcome) return '';
    return this.internalApiResponse.merchant_config.legalAddress;
  }

  get topupAmount(): string {
    if (!this.receivedOutcome) return '';
    return this.$currency.parseWithCode(
      this.internalApiResponse.amount,
      this.internalApiResponse.currency.toUpperCase(),
    );
  }

  get formattedOrderDate(): string {
    const timestamp = this.internalApiResponse?.created_at;
    if (timestamp) {
      const date = new Date(timestamp * 1000);
      const locale = this.internalApiResponse?.locale;
      return date.toLocaleDateString(locale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    }
    return this.$t('vendon-topup.unknown-date').toString();
  }

  get backgroundColor(): string {
    if (this.theme === 0) return 'background-color: white;';
    return 'background-color: #030a14;';
  }

  get cardBackgroundColor(): string {
    if (this.theme === 0) return 'background-color: #f7f7f7;';
    return 'background-color: #111f28;';
  }

  get fontColor(): string {
    if (this.theme === 0) return 'color: #1d303e;';
    return 'color: #fbf9fc;';
  }

  get theme(): number {
    if (!this.receivedOutcome) return 1;
    return this.internalApiResponse?.theme;
  }

  public async created(): Promise<void> {
    if (!this.retrieveUrlParams()) {
      this.redirectToHome();
    } else {
      await this.invokeInternalApi();
    }
  }

  private retrieveUrlParams(): boolean {
    try {
      const requestParams = urlQueryService.getVendonTopUpQuery();
      if (requestParams) {
        this.webOrderId = requestParams?.webOrderId ?? undefined;
        this.merchantId = requestParams?.merchantId ?? undefined;
        this.authorisationUuid = requestParams?.authorisation_uuid ?? undefined;
        if (this.webOrderId && this.merchantId && this.authorisationUuid) {
          return true;
        }
      }
    } catch (err) {
      console.warn(err);
    }
    return false;
  }

  private redirectToHome(): void {
    this.$router.push({ name: Routes.Home });
    this.$toaster.showError('dashboard.invalid-topup-request', true);
  }

  private async invokeInternalApi(): Promise<void> {
    if (this.webOrderId && this.merchantId && this.authorisationUuid) {
      this.$store.dispatch(RootActions.EnableVendonSpinner);
      this.$spinner.showSpinner();
      await vendonGatewayService
        .getVendonGatewayPayment(
          this.merchantId,
          this.authorisationUuid,
          this.webOrderId,
        )
        .then((response) => {
          this.internalApiResponse = response;
          this.setLocaleFromApi(this.internalApiResponse?.locale);
        })
        .catch((err) => {
          this.internalApiResponse = err;
          this.receivedError = true;
        })
        .finally(() => {
          this.receivedOutcome = true;
          this.$spinner.removeSpinner();
          this.$store.dispatch(RootActions.DisableVendonSpinner);
        });
    }
  }

  private setLocaleFromApi(locale: string): void {
    try {
      if (locale && SupportedLocales.includes(locale)) {
        i18n.locale = locale;
      } else {
        i18n.locale = 'en';
      }
    } catch (err) {
      console.warn(err);
    }
  }
}
