import { MutationTree } from 'vuex';
import RootState from './rootState';
import ToasterData from '@/components/toaster/ToasterData.model';

const mutations: MutationTree<RootState> = {
  showToaster(state: RootState, toaster: ToasterData) {
    state.toasters.push(toaster);
  },
  showEternalToaster(state: RootState, toaster: ToasterData) {
    state.toasters.push(toaster);
  },
  removeToaster(state: RootState, id: number) {
    const toasterIndex = state.toasters.findIndex((t) => t.id === id);
    if (toasterIndex !== -1) {
      state.toasters.splice(toasterIndex, 1);
    }
  },
  removeToasters(state: RootState) {
    state.toasters = [];
  },
  showSpinner(state: RootState) {
    state.spinners++;
  },
  removeSpinner(state: RootState) {
    if (state.spinners > 0) {
      state.spinners--;
    }
  },
  enableVendonSpinner(state: RootState) {
    state.vendonSpinner = true;
  },
  disableVendonSpinner(state: RootState) {
    state.vendonSpinner = false;
  },
};

export default mutations;
