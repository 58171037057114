























import { Component, Vue } from 'vue-property-decorator';
import { RootGetters } from '@/store/enums';

@Component
export default class Spinner extends Vue {
  get showSpinner(): boolean {
    return (this.$store.getters[RootGetters.Spinners] as number) > 0;
  }

  get vendonSpinner(): boolean {
    return this.$store.getters[RootGetters.VendonSpinner];
  }
}
