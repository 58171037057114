import { GetterTree } from 'vuex';
import RootState from './rootState';

const getters: GetterTree<RootState, RootState> = {
  toasters: (rootState: RootState) => rootState.toasters,
  spinners: (rootState: RootState) => rootState.spinners,
  vendonSpinner: (rootState: RootState) => rootState.vendonSpinner,
};

export default getters;
