import RootState from './rootState';
import ToasterData from '@/components/toaster/ToasterData.model';

const state: RootState = {
  toasters: [] as ToasterData[],
  spinners: 0,
  vendonSpinner: false,
};

export default state;
