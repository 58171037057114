import BaseService from '@/services/base/base.service';
import axios from 'axios';

class VendonGatewayService extends BaseService {
  public async getVendonGatewayPayment(
    merchantId: string,
    paymentId: string,
    webOrderId: string,
  ): Promise<any> {
    const response = await axios.get(
      `${super.clientsApiBaseUrl()}/${merchantId}/vendongateway/payment/${paymentId}`,
      {
        params: { webOrderId },
      },
    );
    return response.data;
  }
}

export const vendonGatewayService = new VendonGatewayService();
