import {
  QRActions,
  QRQueryParams,
  MobileAppRegistrationParams,
  NexiRedirectParams,
  VendonTopUpParams,
} from '@/models/common/constants';

export class UrlQueryService {
  getQRCodeQuery(): QrAction | undefined {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const qrAction: QrAction = {};
    qrAction.hash = this.searchParam(urlSearchParams, QRQueryParams.Id);
    qrAction.deviceId = this.searchParam(
      urlSearchParams,
      QRQueryParams.DeviceId,
    );
    qrAction.mediaSerial = this.searchParam(
      urlSearchParams,
      QRQueryParams.MediaSerial,
    );
    qrAction.mediaType = this.searchParam(
      urlSearchParams,
      QRQueryParams.MediaType,
    );
    qrAction.action = this.setAction(
      qrAction,
      urlSearchParams,
      QRQueryParams.Action,
    );
    return qrAction ?? undefined;
  }

  getMobileAppRegistrationQuery(): MobileAppRegistrationAction | undefined {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const mobileAppRegistrationAction: MobileAppRegistrationAction = {};
    mobileAppRegistrationAction.hash = this.searchParam(
      urlSearchParams,
      MobileAppRegistrationParams.Hash,
    );
    return mobileAppRegistrationAction ?? undefined;
  }

  getNexiCheckoutQuery(): NexiAction | undefined {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const nexiAction: NexiAction = {};
    nexiAction.checkout = this.searchParam(
      urlSearchParams,
      NexiRedirectParams.Checkout,
    );
    nexiAction.checkoutMsg = this.searchParam(
      urlSearchParams,
      NexiRedirectParams.CheckoutMsg,
    );
    return nexiAction ?? undefined;
  }

  getVendonTopUpQuery(): VendonTopUpAction | undefined {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const vendonTopUpAction: VendonTopUpAction = {};
    vendonTopUpAction.webOrderId = this.searchParam(
      urlSearchParams,
      VendonTopUpParams.WebOrderId,
    );
    vendonTopUpAction.merchantId = this.searchParam(
      urlSearchParams,
      VendonTopUpParams.MerchantId,
    );
    vendonTopUpAction.authorisation_uuid = this.searchParam(
      urlSearchParams,
      VendonTopUpParams.AuthorisationUuid,
    );
    return vendonTopUpAction ?? undefined;
  }

  private searchParam(
    urlSearchParams: URLSearchParams,
    param: string,
  ): string | undefined {
    let value;
    if (urlSearchParams.has(param)) {
      value = urlSearchParams.get(param);
    } else if (urlSearchParams.has(param.toUpperCase())) {
      value = urlSearchParams.get(param.toUpperCase());
    }
    return value ?? undefined;
  }

  private setAction(
    qrAction: QrAction,
    urlSearchParams: URLSearchParams,
    param: string,
  ): string | undefined {
    if (qrAction.hash) {
      return QRActions.QrCheck;
    }

    const action = this.searchParam(urlSearchParams, param);
    return action ?? QRActions.Unknown;
  }
}

export const urlQueryService = new UrlQueryService();

export interface QrAction {
  action?: string;
  hash?: string;
  mediaType?: string; // TODO: try to pase it
  mediaSerial?: string;
  deviceId?: string;
}

export interface MobileAppRegistrationAction {
  hash?: string;
}

export interface NexiAction {
  checkout?: string;
  checkoutMsg?: string;
}

export interface VendonTopUpAction {
  webOrderId?: string;
  merchantId?: string;
  authorisation_uuid?: string;
}
